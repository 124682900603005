
import RestaurantOrderProvidersListCard from '@/components/restaurant/RestaurantOrderProvidersListCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-restaurant-order',
  components: { RestaurantOrderProvidersListCard },
})
export default class ProjectRestaurantOrder extends mixins(ProjectViewMixin) {}
