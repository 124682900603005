
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { BotDomain, DeepPartial, DomainData, FoodOrderProvider } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { AuthMixin } from '@/mixins/AuthMixin';

@Component({
  name: 'restaurant-order-providers-list-card',
})
export default class RestaurantOrderProvidersListCard extends mixins(BotMixin, AuthMixin) {
  foodProviders: FoodOrderProvider[] = this.resetValue();

  isSubmitting = false;

  get foodProvidersHaveChanged(): boolean {
    return !isEqual(this.foodProviders, this.resetValue());
  }

  sortFoodProviders = (a: FoodOrderProvider, b: FoodOrderProvider) => 0;
  filterFoodProviders = (foodProvider: FoodOrderProvider, filterBy: string) =>
    foodProvider.name.toLowerCase().includes(filterBy);
  getFoodProviderKey = (foodProvider: FoodOrderProvider, index: number) => index;

  addFoodProvider() {
    this.foodProviders.push({
      name: '',
      url: '',
    });
  }

  deleteFoodProvider(provider: FoodOrderProvider) {
    const index = this.foodProviders.findIndex((localProvider) => isEqual(provider, localProvider));
    if (index >= 0) {
      this.foodProviders.splice(index, 1);
    }
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBot({
        id: this.bot.id,
        data: {
          domainData: {
            [BotDomain.Restaurant]: {
              foodOrderProviders: this.foodProviders,
            },
          } as DeepPartial<DomainData>,
        },
      });
      this.$notify.success('Successfully updated order providers');
    } catch (e) {
      this.$notify.error({
        title: 'Updating order providers failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  reset() {
    this.foodProviders = this.resetValue();
  }

  resetValue(): FoodOrderProvider[] {
    return (
      this.bot.domainData[BotDomain.Restaurant]?.foodOrderProviders?.map((provider) => ({
        ...provider,
      })) || []
    );
  }
}
